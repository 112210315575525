import "./Auth.css";
import { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import Home from "./Home";
import { Navigate, useNavigate } from "react-router-dom";

export default function Auth({ authsession }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [pw, setPassword] = useState("");
  const navigate = useNavigate();


  const password = process.env.REACT_APP_INVITE_PW;

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      if (pw === password) {
        setAuthenticated(true);
        setPassword("");
        navigate("/home");
      } else {
        throw new Error("Wrong Password");
      }
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  return (
    <div>
      <div className="body">
        <p
          style={{
            justifyContent: "center",
            color: "white",
            display: "flex",
            fontSize: "48px",
            fontFamily: "Montserrat",
            fontStyle: "italic",
            fontWeight: "900",
            margin: "10rem 0rem 1rem 0rem",
            padding: "0rem 0rem 0rem 0rem",
          }}
        >
          SIGN IN
        </p>
        <form onSubmit={handleLogin}>
          <div style={{ marginTop: "0.5rem" }}>
            <label
              style={{
                justifyContent: "start",
                display: "flex",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "800",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              Password
            </label>
            <input
              id="password"
              className="inputField"
              type="password"
              placeholder="Your password"
              value={pw || ""}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="button23">
              LOGIN
          </button>
        </form>
      </div>
    </div>
  );
}
