import "./Home.css";
import { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { AppBar, Box, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

function Home({ session }) {
  const [loading, setLoading] = useState(false);

  const [collectionname, setCollectionName] = useState(null);
  const [chain, setChain] = useState("ethereum");
  const [website, setWebsite] = useState(null);
  const [collectionaddress, setCollectionAddress] = useState(null);


  const navigate = useNavigate();

  const uploadCollection = async (e) => {
    e.preventDefault();

    e.preventDefault();

    let schema = yup.object().shape({
      name: yup.string().required(),
      chain: yup.string().required(),
      website: yup.string().url(),
      collectionaddress: yup.string().required(),
    });

    try {
      setLoading(true);

      const updates = {
        collectionname,
        chain,
        website,
        collectionaddress,
        created_at: new Date(),
      };

      if (
        collectionname != null &&
        chain != null &&
        website != null &&
        collectionaddress !== null
      ) {
        schema
          .isValid({
            name: collectionname,
            chain: chain,
            website: website,
            collectionaddress: collectionaddress,
          })
          .then(function (valid) {
            console.log(valid);
          })
          .catch((err) => {
            console.log(err.name); // ValidationError
            console.log(err.errors); // ['Not a proper email']
            throw new Error(err);
          });
      } else {
        throw new Error("Please fill all input fields");
      }

      let { error } = await supabase.from("collections").insert(updates);

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      window.location.reload(false);
      setLoading(false);
      setCollectionName(null);
      setChain(null);
      setWebsite(null);
      setCollectionAddress(null);
    }
  };

  async function getCollections() {
    const countries = await supabase.from("collections").select();
    console.log(countries);
  }

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          height: "5rem",
          alignContent: "center",
          width: "100%",
          backgroundColor: "#15181f",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <button onClick={() => navigate("/")} className="button2">
            LOGOUT
          </button>
        </Toolbar>
      </AppBar>
      <div className="collection">
        <p
          style={{
            fontSize: "36pt",
            fontFamily: "Montserrat",
            fontStyle: "italic",
            fontWeight: "900",
            color: "white",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          COLLECTION UPLOADER
        </p>
        <form onSubmit={uploadCollection} className="form-widget">
          <div>
            <label
              style={{
                justifyContent: "start",
                display: "flex",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "800",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              COLLECTION NAME
            </label>
            <input
              id="collectionname"
              className="input"
              type="text"
              value={collectionname || ""}
              onChange={(e) => setCollectionName(e.target.value)}
            />
          </div>

          <div>
            <label
              style={{
                justifyContent: "start",
                display: "flex",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "800",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              CHAIN
            </label>
            <select
              name="chain"
              id="chain"
              className="select"
              value={chain || ""}
              onChange={(e) => setChain(e.target.value)}
            >
              <option value="ethereum">Ethereum</option>
              <option value="solana">Solana</option>
            </select>
          </div>

          <div>
            <label
              style={{
                justifyContent: "start",
                display: "flex",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "800",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              WEBSITE
            </label>
            <input
              id="website"
              className="input"
              type="text"
              value={website || ""}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>

          <div>
            <label
              style={{
                justifyContent: "start",
                display: "flex",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "800",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              COLLECTION ADDRESS
            </label>
            <input
              id="collectionaddress"
              className="input"
              type="text"
              value={collectionaddress || ""}
              onChange={(e) => setCollectionAddress(e.target.value)}
            />
          </div>

          <div className="formbutton">
            <button disabled={loading} className="button">
              UPLOAD
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Home;
